@media (max-width: 335px) {
  .pBar .btn-default {
    padding: 5px 5px;
    font-size: 12px;
  }
  /********** Separator **********/

  /*.uProfile {
        padding: 15px;
    }
    .uProfile .heading h4 span {
        margin-left: 0px;
    }
    .uProfile .col-lg-6 span {
        margin-left: 0px;
    }*/
}
@media (max-width: 400px) {
  .signInUp-section .sign-InUp-img .box-bg {
    margin: 20px 0 20px 0;
  }
  /********** Separator **********/
  .userInfo-section {
    flex-direction: column;
  }
  .userInfo-section .userImg img {
    width: 100%;
  }
  .userInfo-section .content .planbg {
    margin: 10px auto 5px auto;
  }
  .userInfo-section .content .cPlan {
    text-align: center;
  }
  .userInfo-section .content .title {
    margin-top: 10px;
  }
  /********** Separator **********/
  .post-section .p-footer .icon li {
    display: inline-block;
    margin-right: 0px;
  }
  /********** Separator **********/
  .inner-hero-banner .hero-banner .overlay-profilePic {
    bottom: -90px !important;
  }
  /********** Separator **********/
  .uProfile.p30 {
    padding: 15px;
  }
}
@media (max-width: 450px) {
  .publish-section .top .c1 li,
  .publish-section .top .c3 li {
    display: block;
  }
  .publish-section .top .c1 li:first-child,
  .publish-section .top .c3 li:first-child {
    margin-bottom: 10px;
  }
  /********** Separator **********/
  .post-section .p-header {
    flex-direction: column;
    align-items: flex-start;
  }
  /********** Separator **********/
  .mPlan {
    padding: 15px;
  }
}
@media (max-width: 500px) {
  .signInUp-section .sign-InUp-img .box-bg {
    font-size: 14px;
    padding: 40px 20px 40px 20px;
  }
  .signInUp-section .sign-InUp-img .box-bg a.btn-default {
    border-radius: 30px;
    font-size: 16px;
  }
  .signInUp-section .sign-InUp-img .box-bg a.btn-default:hover {
    border-radius: 30px;
  }
  .signInUp-section .sign-InUp-img .box-bg a.btn-link {
    color: var(--d-black);
    padding: 0;
    font-size: 14px;
  }
  .signInUp-section .sign-InUp-img .box-bg .aiCenter {
    flex-direction: column;
    text-align: center;
  }
  .signInUp-section .sign-InUp-img .box-bg .aiCenter p {
    font-size: 14px;
    margin: 0;
  }
  /********** Separator **********/
  .publish-section .top .c1 li:first-child,
  .publish-section .top .c3 li:first-child {
    margin-right: 0px;
  }
  /********** Separator **********/
  .inner-hero-banner .hero-banner .overlay-changePhotoCover {
    top: 10px;
    left: 50%;
    height: 32px;
    transform: translateX(-50%);
    width: 50%;
    text-align: center;
  }
}

@media (max-width: 576px) {
}

@media (max-width: 768px) {
  .inner-hero-banner .hero-banner .overlay-profilePic img {
    width: 100px;
    border: var(--d-white) solid 3px;
  }
  .inner-hero-banner .hero-banner .overlay-changePhotoCover {
    right: 20px;
    bottom: 20px !important;
  }
  .inner-hero-banner .hero-banner .overlay-changePhotoCover a {
    font-size: 12px;
    color: var(--d-black);
    text-decoration: none;
  }
  .inner-hero-banner .ePadding {
    flex-direction: column;
    margin: 110px 15px 20px !important;
  }
  .inner-hero-banner .ePadding .rLink {
    text-align: center;
    margin-top: 15px;
  }
  .inner-hero-banner .ePadding .rLink li:first-child {
    padding-left: 0;
  }
  .inner-hero-banner .ePadding .rLink li {
    line-height: 20px !important;
  }
  .inner-hero-banner .ePadding .rLink li a {
    color: var(--d-black);
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s;
  }
}

@media (max-width: 991px) {
  .tSection a.logo {
    margin-right: 0;
  }
  .tSection .navbar-nav .tSearch {
    margin-right: 0;
  }
  .tSection .navbar-nav {
    margin-top: 20px;
  }
  .tSection .navbar-nav:last-child {
    margin-top: 0;
  }
  .tSection .navbar-toggler:focus {
    box-shadow: none;
  }
  /********** Separator **********/
  .post-section .p-body .content {
    text-align: end;
  }
  .post-section .p-body .content li {
    display: inline-block;
    margin: 0 15px auto 0;
    padding-top: 0% !important;
    justify-content: flex-end;
  }
  .post-section .p-body .content li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: -30px auto 0;
  }
  .post-section .p-body .content li a i {
    font-size: 18px;
  }
  /********** Separator **********/
  .inner-hero-banner .hero-banner .overlay-profilePic {
    bottom: -100px;
  }
  .inner-hero-banner .hero-banner .overlay-profilePic-camera {
    bottom: -55px;
  }
  .inner-hero-banner .hero-banner .overlay-changePhotoCover {
    bottom: 20px !important;
  }
  .inner-hero-banner .ePadding {
    margin: 100px 15px 20px;
  }
}

@media (max-width: 1200px) {
  .post-section .p-body .content li {
    padding-top: 10%;
  }
  /********** Separator **********/
  .inner-hero-banner .hero-banner .overlay-changePhotoCover {
    bottom: 20px;
  }
  .inner-hero-banner .ePadding .lSearch .form-control {
    border-radius: 30px;
    width: 100%;
  }
}

@media (min-width: 1201px) {
  .publish-section .bottom {
    margin-top: 55px;
  }
  .inner-hero-banner .hero-banner-img {
    overflow: hidden;
    height: 350px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 350px;
  }
}

@media (min-width: 1400px) {
  .publish-section .bottom {
    margin-top: 63px;
  }
}

/********** Separator **********/

/* ============ only desktop view (top right navbar dropdown)============ */
@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;
  }
  /*.navbar .nav-item:hover .nav-link{ color: #fff;  }*/
  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }
  .navbar .nav-item:hover .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
}
/* ============ desktop view .end// ============ */
