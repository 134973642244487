@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

body {
  color: #667085;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.f-w-700 {
  font-weight: 600;
}

.f-w-400 {
  font-weight: 400;
}

.color-700 {
  color: #344054;
}
.font-14 {
  font-size: 14px;
}
.color-400 {
  color: #667085;
}

.custom-button {
  background: #005a70;
  color: #fff;
  padding: 10px;
}

.custom-button:hover {
  background: #005a70;
  color: #fff;
}

.title h1 {
  font-weight: 600;
  font-size: 40px;
  color: #005a70;
}
button.btn.position-absolute.topab {
  right: 0px;
  top: 37px;
  font-size: 13px;
  border: none;
  font-weight: 600;
  color: #000;
}

button.btn.border.w-100.pop-button {
  background: none;
}
button.btn.border.pop-button {
  background: none;
}
button.btn.btn-secondary {
  background: none;
  color: #344054;
  border: 1px solid #d0d5dd;
}
.modal-footer.justify-content-center {
  border: none;
}

a.color1 {
  color: #005a70;
}
a.mt-5 {
  font-size: 14px;
  color: #005a70;
}
button.btn.custom-gray.w-100.mt-3 {
  background: #e5faff;
  padding: 10px;
}

strong.color-700 {
  font-weight: 600;
}
.stronglinkcolor {
  color: #005a70;
}
.titletext {
  color: #1d4d64;
  font-size: 20px;
}
.font-18 {
  font-size: 17px;
}
.titletextlog {
  color: #667085;
  font-size: 16px;
}
.w-400 {
  font-weight: normal;
}
.f-w-500 {
  font-weight: 500;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: #d0d5dd;
  border: 1px solid #d0d5dd;
  opacity: 1;
  color: #fff;
}
select.form-select {
  color: #98a2b3;
}
::placeholder {
  color: #98a2b3 !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #98a2b3 !important;
}

.d-flex.gap input::placeholder {
  color: #d0d5dd !important;
  opacity: 1;
  /* Firefox */
}

.d-flex.gap input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #d0d5dd !important;
}

.d-flex.gap input.form-control {
  color: #d0d5dd !important;
}

.font39.form-control {
  font-size: 39px;
}

.btn-graylight {
  background: #e5faff !important;
}
.btn {
  font-weight: 600;
}

.form-control {
  color: #101828;
  font-size: 16px;
  padding: 9px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.form-check-input {
  border: 2px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.form-check-input:checked[type='checkbox'] {
  background: #0000 url(images/check-re.svg) !important;
}
.form-check-input:checked {
  background-color: #054757;
  border-color: #054757 !important;
}

.border-bottom {
  border-color: #f2f4f7;
}

.form-select:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: none;
}

/* Style for the radio input */
.radio-input {
  display: none;
}

/* Style for the card container */
.card {
  border-radius: 16px;
  padding: 20px;
  margin: 15px 0;
  display: block;
  cursor: pointer;
  color: #667085;
  border: 1px solid var(--gray-200, #eaecf0);
  background: #fff;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

/* Style for the label */
.custom-bages {
  background: #e5faff;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 45px;
  color: #7e2410;
}
.success-bg {
  background: #d1fadf !important;
}
.success-color {
  color: #12b76a;
}
.blackgray {
  color: #101828;
}
.pop-button {
  background: #054757;
}

.error-holder {
  column-gap: 10px;
}
.error-holder span {
  border-radius: 4px;
  opacity: 0.5;
  background: #f97066;
  display: block;
  height: 8px;
  width: 100%;
}
.error-holder span:nth-child(2) {
  background: #fdb022;
}
.error-holder span:nth-child(3) {
  background: #32d583;
}
.characters-holder {
  border-radius: 0px 16px 16px 16px;
  background: #fff;
  padding: 16px;
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
.list-check li {
  margin-bottom: 5px;
  color: #000;
}
.fleet-button {
  border-radius: 16px;
  background: #fddcab;
}

.modal-backdrop.fade.show {
  background: rgba(52, 64, 84, 0.7);
  opacity: 1;
}
.titlet374 {
  color: #344054;
}
.gap {
  column-gap: 15px;
}
.unsuccessfulcolor {
  color: #f63d68;
}
.refreshbtm {
  background: none;
  border: none;
  margin-left: 15px;
  font-weight: 600;
}
.verifybutton {
  border-radius: 8px;
  border: 1px solid #e5faff;
  background: #e5faff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 7px;
  font-weight: 600;
}
.verifedbutton {
  border-radius: 8px;
  border: 1px solid #ecfdf3;
  background: #ecfdf3;
  color: #027a48;
  padding: 7px;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.unverifedbutton {
  color: #b42318;
  padding: 7px;
  font-weight: 600;
  border: 1px solid #fef3f2;
  background: #fef3f2;
  border-radius: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

@media (min-width: 768px) {
  .characters-holder {
    position: absolute;
    left: 107%;
    top: 50px;
    min-width: 251px;
  }
}

@media (max-width: 300px) {
  body {
    font-size: 13px;
  }
  .title h1 {
    font-size: 24px;
  }
}

@media (min-width: 310px) and (max-width: 400px) {
  body {
    font-size: 13px;
  }
  .title h1 {
    font-size: 30px;
  }
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #667085;
  width: 120px;
  font-weight: 600;
  height: 40px;
  background: url(images/chevron-down.svg) no-repeat;
  background-position: right center;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 15px;
}

.select-options {
  display: none;
  position: absolute;
  top: 120%;
  right: 0;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 120px;
}
.select-options li {
  margin: 0;
  padding: 5px 0 !important;
  text-indent: 15px;
  font-weight: 500;
  color: #344054;
  background: #fff;
}
.select-options li.is-selected,
.select-options li:hover {
  background: #f9fafb;
}

/*Email verifaction page  */
.create-account-btn {
  border-radius: 8px;
  border: 1px solid var(--Brand-600, #054757);
  background: var(--Brand-600, #054757);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--White, #fff);
  font-size: 14px;
  font-weight: 600;
  padding: 8px 11px;
}
button.bg-transparent.login-btn {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.offcanvas-body .navbar-nav.custonnavstyle li a {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.dropdown-toggle::after {
  background: url(images/navbar-down-icon.svg);
  width: 15px;
  height: 11px;
  background-size: 100%;
  border: none;
  background-position: 0 0;
  margin: 0;
  padding: 0;
  top: 2px;
  position: relative;
}
.dropdown-menu {
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 0;
  list-style: none;
  border: none;
}
@media (max-width: 767px) {
  .select-styled {
    padding: 10px 0;
  }
}

.modal-content h2,
.modal-content h3,
.modal-content h4,
.modal-content h5 {
  color: #101828;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}


/* error message class in  createFrightEvent */
.errorMessage{
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

}

.goingButton {
  display: flex;
  align-items: center;
}

#sliderForEvent {
  width: 100%;
}

#sliderForEvent .slick-slide img {
  max-height: 100vh;
  width: 100%; /*100vw*/
}

#sliderForEvent .slick-prev {
  left: 0;
  height: 30px;
  background-color: antiquewhite;
}

#sliderForEvent .slick-next {
  right: -11px;
  height: 30px;
  background-color: antiquewhite;
}
#sliderForEvent .slick-list {
  margin-left: 10px;
}

.selectedMerridian {
  opacity: 1.5 !important; /* fully opaque */
}

.unselectedMerridian {
  opacity: 0.5 !important; /* semi-transparent */
}

#sliderForEvent .slick-slide img { 
  height: 100%; width: 100%;
}


#sliderForEvent .slick-slide
{
    height: inherit !important;
}

#sliderForEvent  .sliderForEventSlickListheight {
  height: calc(100% - 45px)
}
#sliderForEvent .slick-slider, #sliderForEvent .slick-list , #sliderForEvent .slick-track, #sliderForEvent .slick-slide > div {
  height: inherit;
}

#sliderForEvent .slick-slide > div > img{
  object-fit: contain;
}

#sliderForEvent ul.slick-dots {
  bottom: 30px;
}

#sliderForEvent .slick-track{
  margin-top: 3vh;
}


/* feed Publish  button  start */

@keyframes post-blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.publish-save-blinking {
  animation: post-blink 1s infinite;
}

.publish-save-loading {
  background-color: transparent;
  color: #344054;
  border-color: #344054;
}


/* feed Publish  button end*/
