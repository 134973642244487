@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

body {
  color: #667085;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.f-w-700 {
  font-weight: 600 !important ;
}

.f-w-400 {
  font-weight: 400;
}

.color-700 {
  color: #344054;
}
.font-14 {
  font-size: 14px;
}
.color-400 {
  color: #667085;
}

.custom-button {
  background: #005a70 !important;
  color: #fff !important;
  padding: 10px !important;
}

.custom-button:hover {
  background: #005a70 !important;
  color: #fff !important;
}

.title h1 {
  font-weight: 600;
  font-size: 40px;
  color: #005a70;
}
button.btn.position-absolute.topab {
  right: 0px;
  top: 37px;
  font-size: 13px;
  border: none;
  font-weight: 600;
  color: #000;
}

button.btn.border.w-100.pop-button {
  background: none;
}
button.btn.border.pop-button {
  background: none;
}
button.btn.btn-secondary {
  background: none;
  color: #344054;
  border: 1px solid #d0d5dd;
}
.modal-footer.justify-content-center {
  border: none;
}

a.color1 {
  color: #005a70;
}
a.mt-5 {
  font-size: 14px;
  color: #005a70;
}
button.btn.custom-gray.w-100.mt-3 {
  background: #e5faff;
  padding: 10px;
}

strong.color-700 {
  font-weight: 600;
}
.stronglinkcolor {
  color: #005a70 !important;
}
.titletext {
  color: #1d4d64;
  font-size: 20px;
}
.font-18 {
  font-size: 17px;
}
.titletextlog {
  color: #667085;
  font-size: 16px;
}
.w-400 {
  font-weight: normal;
}
.f-w-500 {
  font-weight: 500;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: #d0d5dd;
  border: 1px solid #d0d5dd;
  opacity: 1;
  color: #fff;
}
select.form-select {
  color: #98a2b3;
}
::placeholder {
  color: #98a2b3 !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #98a2b3 !important;
}

.d-flex.gap input::placeholder {
  color: #d0d5dd !important;
  opacity: 1;
  /* Firefox */
}

.d-flex.gap input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #d0d5dd !important;
}

.d-flex.gap input.form-control {
  color: #d0d5dd !important;
}

.font39.form-control {
  font-size: 39px;
}

.btn-graylight {
  background: #e5faff !important;
}
.btn {
  font-weight: 600;
}

.form-control {
  color: #101828;
  font-size: 16px;
  padding: 9px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.form-check-input {
  border: 2px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.form-check-input:checked[type='checkbox'] {
  /* background: #0000 url("/Assets/New/images/check-re.svg") !important; */
  background: #0000;
}
.form-check-input:checked {
  background-color: #054757;
  border-color: #054757 !important;
}

.border-bottom {
  border-color: #f2f4f7;
}

.form-select:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: none;
}

/* Style for the radio input */
.radio-input {
  display: none;
}

/* Style for the card container */
.card {
  border-radius: 16px;
  padding: 20px;
  margin: 15px 0;
  display: block;
  cursor: pointer;
  color: #667085;
  border: 1px solid var(--gray-200, #eaecf0);
  background: #fff;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

/* Style for the label */
.custom-bages {
  background: #e5faff;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 45px;
  color: #7e2410;
}
.success-bg {
  background: #d1fadf !important;
}
.success-color {
  color: #12b76a;
}
.blackgray {
  color: #101828;
}
.pop-button {
  background: #054757;
}

.error-holder {
  column-gap: 10px;
}
.error-holder span {
  border-radius: 4px;
  opacity: 0.5;
  background: #f97066;
  display: block;
  height: 8px;
  width: 100%;
}
.error-holder span:nth-child(2) {
  background: #fdb022;
}
.error-holder span:nth-child(3) {
  background: #32d583;
}
.characters-holder {
  border-radius: 0px 16px 16px 16px;
  background: #fff;
  padding: 16px;
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
.list-check li {
  margin-bottom: 5px;
  color: #000;
}
.fleet-button {
  border-radius: 16px;
  background: #fddcab;
}

.modal-backdrop.fade.show {
  background: rgba(52, 64, 84, 0.7);
  opacity: 1;
}
.titlet374 {
  color: #344054;
}
.gap {
  column-gap: 15px;
}
.unsuccessfulcolor {
  color: #f63d68;
}
.refreshbtm {
  background: none;
  border: none;
  margin-left: 15px;
  font-weight: 600;
}
.verifybutton {
  border-radius: 8px;
  border: 1px solid #e5faff;
  background: #e5faff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 7px;
  font-weight: 600;
}
.verifedbutton {
  border-radius: 8px;
  border: 1px solid #ecfdf3;
  background: #ecfdf3;
  color: #027a48;
  padding: 7px;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.unverifedbutton {
  color: #b42318;
  padding: 7px;
  font-weight: 600;
  border: 1px solid #fef3f2;
  background: #fef3f2;
  border-radius: 8px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

@media (min-width: 768px) {
  .characters-holder {
    position: absolute;
    left: 107%;
    top: 50px;
    min-width: 251px;
  }
}

@media (max-width: 300px) {
  body {
    font-size: 13px;
  }
  .title h1 {
    font-size: 24px;
  }
}

@media (min-width: 310px) and (max-width: 400px) {
  body {
    font-size: 13px;
  }
  .title h1 {
    font-size: 30px;
  }
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #667085;
  width: 120px;
  font-weight: 600;
  height: 40px;
  /* background:url(../images/chevron-down.svg) no-repeat; */
  background-position: right center;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid #f9fafb;
  padding: 8px 15px;
}

.select-options {
  display: none;
  position: absolute;
  top: 120%;
  right: 0;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 120px;
}
.select-options li {
  margin: 0;
  padding: 5px 0 !important;
  text-indent: 15px;
  font-weight: 500;
  color: #344054;
  background: #fff;
}
li.is-selected,
li:hover {
  background: #f9fafb;
}
/*Custiom css*/

.noUnderline {
  text-decoration: none !important;
}

.oneRem {
  font-size: 1rem !important;
}
.cursorPointer {
  cursor: pointer !important;
}

.loaderWrapper {
  justify-content: center;
  display: flex;
}
.loader {
  border: 12px solid #f79009;
  border-radius: 50%;
  border-top: 12px solid #f2f4f7;

  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
